define("frontend-app/pods/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndexRoute extends Ember.Route {
    async model() {
      try {
        let outreachResources = await this.store.findAll('outreach-resource');
        let fundingResources = await this.store.findAll('funding-resource');
        let currentDate = new Date().toISOString();
        let featuredOutreach = null;
        let recentFunding = null;
        let expiringFunding = null;
        outreachResources.forEach(function (resource) {
          if (resource.status == 'active') {
            console.log(resource.startDate);

            if (resource.startDate) {
              if (resource.startDate < currentDate) {
                if (featuredOutreach) {
                  if (resource.startDate > featuredOutreach.startDate) {
                    featuredOutreach = resource;
                  }
                } else {
                  featuredOutreach = resource;
                }
              }
            }
          }
        });
        fundingResources.forEach(function (resource) {
          if (resource.status == 'active') {
            if (recentFunding) {
              if (resource.dateCreated > recentFunding.dateCreated) {
                recentFunding = resource;
              }
            } else {
              recentFunding = resource;
            }

            if (resource.expires) {
              console.log(resource.expiryDate);

              if (resource.expiryDate > currentDate) {
                if (expiringFunding) {
                  if (resource.expiryDate < expiringFunding.expiryDate) {
                    expiringFunding = resource;
                  }
                } else {
                  expiringFunding = resource;
                }
              }
            }
          }
        });
        return {
          featuredOutreach: featuredOutreach,
          recentFunding: recentFunding,
          expiringFunding: expiringFunding
        };
      } catch (err) {
        console.log(err);
      }
    }

  }

  _exports.default = IndexRoute;
});