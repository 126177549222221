define("frontend-app/pods/funding/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FundingIndexRoute extends Ember.Route {
    async model() {
      try {
        let resources = await this.store.findAll('funding-resource');
        let tags = await this.store.findAll('tag');
        tags = tags.filter(function (tag) {
          return tag.type == 'funding';
        });
        let currentDate = new Date().toISOString();
        let active = resources.filter(function (resource) {
          if (resource.status == 'active') {
            if (resource.expires) {
              if (resource.expiryDate > currentDate) {
                return true;
              } else {
                return false;
              }
            } else {
              return true;
            }
          } else {
            return false;
          }
        });
        let expired = resources.filter(function (resource) {
          if (resource.status == 'active') {
            if (resource.expires) {
              if (resource.expiryDate < currentDate) {
                return true;
              } else {
                return false;
              }
            } else {
              return false;
            }
          } else {
            return false;
          }
        });
        return {
          activeResources: active,
          expiredResources: expired,
          tags: tags
        };
      } catch (err) {
        console.log(err);
      }
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      let self = this;
      model.activeResources.forEach(function (resource) {
        controller.addSearchItem(resource);
      });
      model.expiredResources.forEach(function (resource) {
        controller.addSearchItem(resource);
      });
    }

  }

  _exports.default = FundingIndexRoute;
});