define("frontend-app/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "leYqJ/Ld",
    "block": "{\"symbols\":[],\"statements\":[[1,[24,[\"model\",\"title\"]],false],[0,\"\\n\"],[1,[28,\"head-tags\",null,[[\"headTags\"],[[24,[\"model\",\"headTags\"]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend-app/templates/head.hbs"
    }
  });

  _exports.default = _default;
});