define("frontend-app/adapters/application", ["exports", "ember-torusoft-api-adapter"], function (_exports, _emberTorusoftApiAdapter) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class applicationAdapter extends _emberTorusoftApiAdapter.TorusoftApiAdapter {}

  _exports.default = applicationAdapter;
});