define("frontend-app/pods/funding/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class FundingRoute extends Ember.Route {}

  _exports.default = FundingRoute;
});