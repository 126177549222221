define("frontend-app/components/fields/field-address-multiple-item", ["exports", "ember-torusoft-core/components/fields/field-address-multiple-item"], function (_exports, _fieldAddressMultipleItem) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fieldAddressMultipleItem.default;
    }
  });
});