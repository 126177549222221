define("frontend-app/pods/components/sharing/twitter-share-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "X8V40UWa",
    "block": "{\"symbols\":[\"&attrs\"],\"statements\":[[7,\"a\",false],[12,\"href\",[29,[\"https://twitter.com/share?url=\",[23,0,[\"url\"]]]]],[12,\"target\",\"_blank\"],[13,1],[8],[5,\"fa-icon\",[],[[\"@prefix\",\"@icon\"],[\"fab\",\"twitter\"]]],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend-app/pods/components/sharing/twitter-share-button/template.hbs"
    }
  });

  _exports.default = _default;
});