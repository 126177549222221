define("frontend-app/pods/outreach/index/controller", ["exports", "frontend-app/metadata", "elasticlunr"], function (_exports, _metadata, _elasticlunr) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _dec7, _dec8, _dec9, _dec10, _dec11, _dec12, _dec13, _dec14, _dec15, _dec16, _class, _descriptor, _descriptor2, _descriptor3, _descriptor4, _descriptor5, _descriptor6, _descriptor7, _descriptor8, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OutreachIndexController = (_dec = Ember.inject.service, _dec2 = Ember._tracked, _dec3 = Ember._tracked, _dec4 = Ember._tracked, _dec5 = Ember._tracked, _dec6 = Ember._tracked, _dec7 = Ember._tracked, _dec8 = Ember._tracked, _dec9 = Ember._action, _dec10 = Ember._action, _dec11 = Ember._action, _dec12 = Ember._action, _dec13 = Ember._action, _dec14 = Ember._action, _dec15 = Ember._action, _dec16 = Ember._action, (_class = (_temp = class OutreachIndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "locale", _descriptor, this);

      _initializerDefineProperty(this, "sortBy", _descriptor2, this);

      _initializerDefineProperty(this, "provinceFilter", _descriptor3, this);

      _initializerDefineProperty(this, "tagFilter", _descriptor4, this);

      _initializerDefineProperty(this, "audienceFilter", _descriptor5, this);

      _initializerDefineProperty(this, "keywordFilter", _descriptor6, this);

      _initializerDefineProperty(this, "keywordInput", _descriptor7, this);

      _initializerDefineProperty(this, "filterMode", _descriptor8, this);

      _defineProperty(this, "region_lookup", _metadata.default.regions);

      _defineProperty(this, "index", (0, _elasticlunr.default)(function () {
        this.addField('title');
        this.addField('content');
        this.addField('tags');
        this.setRef('id');
      }));
    }

    get audienceTypes() {
      let allAudienceTypes = [];
      let self = this;
      this.model.activeResources.forEach(function (resource) {
        resource.audienceTypes[self.locale.activeLocale].forEach(function (activity) {
          if (allAudienceTypes.indexOf(activity) == -1) {
            allAudienceTypes.push(activity);
          }
        });
      });
      return allAudienceTypes.sort();
    }

    get activeResults() {
      let results = this.model.activeResources;
      let locale = this.locale.activeLocale;
      let self = this;

      if (this.filterMode == "browse") {
        if (this.provinceFilter.length) {
          results = results.filter(function (resource) {
            let included = false;
            self.provinceFilter.forEach(function (province) {
              resource.address.forEach(function (address) {
                if (address.address.state == province) {
                  included = true;
                }
              });
            });
            return included;
          });
        }

        if (this.tagFilter.length) {
          results = results.filter(function (resource) {
            let included = false;
            self.tagFilter.forEach(function (filter_tag) {
              resource.tags.forEach(function (tag) {
                if (tag.name[locale] == filter_tag) {
                  included = true;
                }
              });
            });
            return included;
          });
        }

        if (this.audienceFilter.length) {
          results = results.filter(function (resource) {
            let included = false;
            self.audienceFilter.forEach(function (filter_audience) {
              resource.audienceTypes[locale].forEach(function (audience) {
                if (audience == filter_audience) {
                  included = true;
                }
              });
            });
            return included;
          });
        }
      } else if (this.filterMode == 'keyword') {
        let keyword_ids = Ember.A(this.search(this.keywordFilter));
        results = results.filter(function (resource) {
          return keyword_ids.includes(resource.id);
        });
      }

      this.sortType = 'active';
      return results.sort(this.sortFunction.bind(this));
    }

    toggleSort(event) {
      this.sortBy = event.target.value;
    }

    toggleProvince(province) {
      if (this.provinceFilter.indexOf(province) > -1) {
        this.provinceFilter.removeAt(this.provinceFilter.indexOf(province));
      } else {
        this.provinceFilter.addObject(province);
      }
    }

    toggleTag(tag) {
      if (this.tagFilter.indexOf(tag) > -1) {
        this.tagFilter.removeAt(this.tagFilter.indexOf(tag));
      } else {
        this.tagFilter.addObject(tag);
      }
    }

    toggleMobileTag(event) {
      if (event.target.value == "") {
        this.tagFilter = [];
      } else if (this.tagFilter.indexOf(event.target.value) > -1) {
        this.tagFilter.removeAt(this.tagFilter.indexOf(event.target.value));
      } else {
        this.tagFilter.replace(0, 1, [event.target.value]);
      }
    }

    toggleAudience(audience) {
      if (this.audienceFilter.indexOf(audience) > -1) {
        this.audienceFilter.removeAt(this.audienceFilter.indexOf(audience));
      } else {
        this.audienceFilter.addObject(audience);
      }
    }

    toggleMobileAudience(event) {
      if (event.target.value == "") {
        this.audienceFilter = [];
      } else if (this.audienceFilter.indexOf(event.target.value) > -1) {
        this.audienceFilter.removeAt(this.audienceFilter.indexOf(event.target.value));
      } else {
        this.audienceFilter.replace(0, 1, [event.target.value]);
      }
    }

    sortFunction(a, b) {
      if (this.sortBy == 'start_date') {
        let aDate = new Date(a.startDate);
        let bDate = new Date(b.startDate);

        if (aDate > bDate) {
          return -1;
        }

        if (aDate < bDate) {
          return 1;
        }
      } else if (this.sortBy == 'alphabetical') {
        if (a.title[this.locale.activeLocale] > b.title[this.locale.activeLocale]) {
          return 1;
        }

        if (a.title[this.locale.activeLocale] < b.title[this.locale.activeLocale]) {
          return -1;
        }
      }

      return 0;
    }

    onFilterTextChange() {
      Ember.run.debounce(this, this.applyKeywordFilter, 500);
    }

    applyKeywordFilter() {
      this.keywordFilter = this.keywordInput;

      if (this.keywordFilter == "") {
        this.filterMode = 'browse';
      } else {
        this.filterMode = 'keyword';
        this.provinceFilter = [];
        this.tagFilter = "";
        this.elgibilityFilter = "";
      }
    }

    addSearchItem(row) {
      let content = "";
      let self = this;
      row.content[this.locale.activeLocale].forEach(function (obj) {
        if (obj.content) {
          content = content.concat(obj.content);
        } else if (obj.title) {
          content = content.concat(obj.title);
        }
      }); //remove all html tags and such

      let strippedContent = content.replace(/(<([^>]+)>)/gi, "");
      let entry = {
        id: row.id,
        title: row.title[self.locale.activeLocale],
        content: strippedContent,
        tags: row.tags.map(function (tag) {
          return tag.name[self.locale.activeLocale];
        }).join(" ")
      };
      this.index.addDoc(entry);
    }

    search(query) {
      return this.index.search(query, {
        expand: true
      }).mapBy('ref');
    }

    resetFilter() {
      this.tagFilter = [];
      this.audienceFilter = [];
      this.provinceFilter = [];
      this.keywordFilter = "";
      this.keywordInput = "";
      this.filterMode = "browse";
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "sortBy", [_dec2], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "start_date";
    }
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "provinceFilter", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor4 = _applyDecoratedDescriptor(_class.prototype, "tagFilter", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor5 = _applyDecoratedDescriptor(_class.prototype, "audienceFilter", [_dec5], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return [];
    }
  }), _descriptor6 = _applyDecoratedDescriptor(_class.prototype, "keywordFilter", [_dec6], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor7 = _applyDecoratedDescriptor(_class.prototype, "keywordInput", [_dec7], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "";
    }
  }), _descriptor8 = _applyDecoratedDescriptor(_class.prototype, "filterMode", [_dec8], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: function () {
      return "browse";
    }
  }), _applyDecoratedDescriptor(_class.prototype, "toggleSort", [_dec9], Object.getOwnPropertyDescriptor(_class.prototype, "toggleSort"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleProvince", [_dec10], Object.getOwnPropertyDescriptor(_class.prototype, "toggleProvince"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleTag", [_dec11], Object.getOwnPropertyDescriptor(_class.prototype, "toggleTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMobileTag", [_dec12], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMobileTag"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleAudience", [_dec13], Object.getOwnPropertyDescriptor(_class.prototype, "toggleAudience"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "toggleMobileAudience", [_dec14], Object.getOwnPropertyDescriptor(_class.prototype, "toggleMobileAudience"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "onFilterTextChange", [_dec15], Object.getOwnPropertyDescriptor(_class.prototype, "onFilterTextChange"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "resetFilter", [_dec16], Object.getOwnPropertyDescriptor(_class.prototype, "resetFilter"), _class.prototype)), _class));
  _exports.default = OutreachIndexController;
});