define("frontend-app/pods/components/sharing/email-share-button/template", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "zIXaIqro",
    "block": "{\"symbols\":[\"@subject\",\"&attrs\"],\"statements\":[[7,\"a\",false],[12,\"href\",[29,[\"mailto:?subject=\",[23,1,[]],\"&body=Read more about this resource at \",[23,0,[\"url\"]],\".\"]]],[13,2],[8],[5,\"fa-icon\",[],[[\"@icon\"],[\"envelope\"]]],[9],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "frontend-app/pods/components/sharing/email-share-button/template.hbs"
    }
  });

  _exports.default = _default;
});