define("frontend-app/utils/user-password-reset", ["exports", "ember-torusoft-core/utils/user-password-reset"], function (_exports, _userPasswordReset) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _userPasswordReset.default;
    }
  });
});