define("frontend-app/pods/outreach/view/route", ["exports", "frontend-app/config/environment"], function (_exports, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  let OutreachViewRoute = (_dec = Ember.inject.service, (_class = (_temp = class OutreachViewRoute extends Ember.Route {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "locale", _descriptor, this);
    }

    async model(params) {
      try {
        return this.store.queryRecord('outreach-resource', {
          slug: params.slug
        }, {
          include: "organizations"
        });
      } catch (err) {
        console.log(err);
      }
    }

    afterModel(model) {
      this.setHeadTags(model);
    }

    setHeadTags(model) {
      let image;

      if (model.coverImage) {
        image = _environment.default.apiServer.host + '/api/outreach-resources/' + model.id + '/attachments/' + model.coverImage;
      } else {
        //TODO: add default image to assets
        image = _environment.default.apiServer.host + "/assets/images/logo_ccaa.jpg";
      }

      let headTags = [{
        type: 'meta',
        attrs: {
          property: 'og:title',
          content: model.title[this.locale.activeLocale]
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:type',
          content: 'website'
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:image',
          content: image
        }
      }, {
        type: 'meta',
        attrs: {
          property: 'og:description',
          //TODO: add more descriptive description
          content: "Learn more about this resource."
        }
      }];
      this.headTags = headTags;
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = OutreachViewRoute;
});