define("frontend-app/pods/outreach/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OutreachRoute extends Ember.Route {}

  _exports.default = OutreachRoute;
});