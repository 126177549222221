define("frontend-app/pods/index/controller", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _dec, _class, _descriptor, _temp;

  function _initializerDefineProperty(target, property, descriptor, context) { if (!descriptor) return; Object.defineProperty(target, property, { enumerable: descriptor.enumerable, configurable: descriptor.configurable, writable: descriptor.writable, value: descriptor.initializer ? descriptor.initializer.call(context) : void 0 }); }

  function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }

  function _initializerWarningHelper(descriptor, context) { throw new Error('Decorating class property failed. Please ensure that ' + 'proposal-class-properties is enabled and runs after the decorators transform.'); }

  function findText(content) {
    let text = "No description is available.";
    let flag = true;
    content.forEach(function (item) {
      if (item.type == 'text' && flag) {
        text = item.content;
        flag = false;
      }
    });
    return text;
  }

  let IndexController = (_dec = Ember.inject.service, (_class = (_temp = class IndexController extends Ember.Controller {
    constructor(...args) {
      super(...args);

      _initializerDefineProperty(this, "locale", _descriptor, this);
    }

    get featuredOutreachText() {
      if (this.model.featuredOutreach) {
        return findText(this.model.featuredOutreach.content[this.locale.activeLocale]);
      } else {
        return "";
      }
    }

    get recentFundingText() {
      if (this.model.recentFunding) {
        return findText(this.model.recentFunding.content[this.locale.activeLocale]);
      } else {
        return "";
      }
    }

    get expiringFundingText() {
      if (this.model.expiringFunding) {
        return findText(this.model.expiringFunding.content[this.locale.activeLocale]);
      } else {
        return "";
      }
    }

  }, _temp), (_descriptor = _applyDecoratedDescriptor(_class.prototype, "locale", [_dec], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  })), _class));
  _exports.default = IndexController;
});