define("frontend-app/components/fields/field-text", ["exports", "ember-torusoft-core/components/fields/field-text"], function (_exports, _fieldText) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  Object.defineProperty(_exports, "default", {
    enumerable: true,
    get: function () {
      return _fieldText.default;
    }
  });
});