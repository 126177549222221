define("frontend-app/metadata", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    regions: {
      "AB": "Alberta",
      "BC": "British Columbia",
      "MB": "Manitoba",
      "NB": "New Brunswick",
      "NL": "Newfoundland and Labrador",
      "NS": "Nova Scotia",
      "NT": "Northwest Territories",
      "NU": "Nunavut",
      "ON": "Ontario",
      "PE": "Prince Edward Island",
      "QC": "Quebec",
      "SK": "Saskatchewan",
      "YT": "Yukon",
      "AL": "Alabama",
      "AK": "Alaska",
      "AZ": "Arizona",
      "AR": "Arkansas",
      "CA": "California",
      "CO": "Colorado",
      "CT": "Connecticut",
      "DE": "Delaware",
      "DC": "District Of Columbia",
      "FL": "Florida",
      "GA": "Georgia",
      "HI": "Hawaii",
      "ID": "Idaho",
      "IL": "Illinois",
      "IN": "Indiana",
      "IA": "Iowa",
      "KS": "Kansas",
      "KY": "Kentucky",
      "LA": "Louisiana",
      "ME": "Maine",
      "MD": "Maryland",
      "MA": "Massachusetts",
      "MI": "Michigan",
      "MN": "Minnesota",
      "MS": "Mississippi",
      "MO": "Missouri",
      "MT": "Montana",
      "NE": "Nebraska",
      "NV": "Nevada",
      "NH": "New Hampshire",
      "NJ": "New Jersey",
      "NM": "New Mexico",
      "NY": "New York",
      "NC": "North Carolina",
      "ND": "North Dakota",
      "OH": "Ohio",
      "OK": "Oklahoma",
      "OR": "Oregon",
      "PA": "Pennsylvania",
      "RI": "Rhode Island",
      "SC": "South Carolina",
      "SD": "South Dakota",
      "TN": "Tennessee",
      "TX": "Texas",
      "UT": "Utah",
      "VT": "Vermont",
      "VA": "Virginia",
      "WA": "Washington",
      "WV": "West Virginia",
      "WI": "Wisconsin",
      "WY": "Wyoming"
    },
    fileIcons: {
      "text/plain": "fa-file-text-o",
      "text/csv": "fa-file-text-o",
      "image/jpeg": "fa-file-image-o",
      "image/png": "fa-file-image-o",
      "application/pdf": "fa-file-pdf-o",
      "application/zip": "fa-file-zip-o",
      "application/vnd.ms-powerpoint": "fa-file-powerpoint-o",
      "application/vnd.ms-excel": "fa-file-excel-o",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document": "fa-file-word-o",
      "application/vnd.openxmlformats-officedocument.presentationml.presentation": "fa-file-powerpoint-o",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": "fa-file-excel-o"
    }
  };
  _exports.default = _default;
});