define("frontend-app/pods/outreach/index/route", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class OutreachIndexRoute extends Ember.Route {
    async model() {
      try {
        let resources = await this.store.findAll('outreach-resource');
        let tags = await this.store.findAll('tag');
        tags = tags.filter(function (tag) {
          return tag.type == 'outreach';
        });
        let active = resources.filter(function (resource) {
          return resource.status == 'active';
        });
        return {
          activeResources: active,
          tags: tags
        };
      } catch (err) {
        console.log(err);
      }
    }

    setupController(controller, model) {
      super.setupController(controller, model);
      let self = this;
      model.activeResources.forEach(function (resource) {
        controller.addSearchItem(resource);
      });
    }

  }

  _exports.default = OutreachIndexRoute;
});